@import "~simple-keyboard/build/css/index.css";

#perspective-keyboard {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #000;
    z-index: 9999;
    width: 100%;
    padding-top: 50px;
    i {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 30px;
        background: #ececec;
        padding: 10px 15px;
    }
}

#footer {
    margin-bottom: 220px;
}